.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 3rem;
  background-color: var(--color-accent-1); /* Purple background */
  color: var(--color-white);
  border-top: 1px solid var(--color-white); /* Changing border color to white */
  font-size: 0.875rem; /* Small font size */
}

.footer-main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative; /* To position the center element absolutely */
}

.footer-left,
.footer-right,
.footer-social {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-social {
  margin-top: 1rem;
  display: flex;
}

.footer-left {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.footer a {
  text-decoration: none;
  color: var(--color-white); /* Changing link color to white */
  transition: color 0.3s ease;
}

.footer a:hover {
  color: var(--color-accent-2); /* Changed to Light Blue */
}

.footer svg {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width: 700px) {
  .footer-main {
    flex-direction: column;
    align-items: center;
  }

  .footer-social {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem; /* Space between social icons and other sections */
  }

  .footer-left,
  .footer-right {
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0;
    position: static;
    transform: none;
  }
}