.shared_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  height: var(--nav-height);
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--color-accent-1); /* Replaced Light Blue with Purple */
  color: var(--color-white);
  border-bottom: 1px solid var(--color-white);
}

.shared_nav-left a.shared_nav-logo {
  margin-right: 2rem;
}

.shared_nav-right {
  display: flex;
  gap: 2rem; /* This adds more space between the links */
}

.shared_nav a {
  color: var(--color-white);
  text-decoration: none;
}

.shared_nav a:hover {
  color: var(--color-accent-2); /* Changed to Light Blue */
}