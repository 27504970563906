.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.main-content {
  margin-top: var(--nav-height);
  flex-grow: 1;
}

/* media-queries */
@media screen and (max-width: 700px) {
}
