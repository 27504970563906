:root {
  /* colors */
  --color-black: #333333; /* Dark gray for better readability */
  --color-white: #ffffff;
  --color-accent-1: rgb(114, 9, 183); /* Purple */
  --color-accent-2: rgb(76, 201, 240); /* Light Blue */
  --color-accent-3: rgb(247, 37, 133); /* Pink */
  --color-accent-4: #ff5500; /* Bright orange */
  --color-accent-5: #ffaa00; /* Bright yellow */

  --text-color: var(--color-black); /* Change text color to dark gray */
  --bg-color: var(--color-white); /* Background color to white */
  --accent-color: var(--color-accent-1); /* Default accent color */
}