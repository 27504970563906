.animate-in-view {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.animate-in-view.in-view {
  opacity: 1;
  transform: translateY(0);
}
