.features_section {
  background-color: var(--color-white); /* White background */
  color: var(--color-black);
  padding: 5rem 1rem;
  text-align: center;
}

.features_list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
}

.feature_item h3 {
  font-size: 1.5rem;
  color: var(--color-accent-2); /* Replaced text color to Light Blue */
}

.feature_item p {
  font-size: 1rem;
  color: var(--color-black); /* Changed text color to black for better readability */
}

.video-container {
  position: relative;
  max-width: 560px; /* Set to match the native width of the iframe */
  width: 100%; /* Ensure it resizes down for mobile devices */
  margin: 0 auto; /* Center the video container */
  background: #000;
}

.video-container iframe {
  width: 100%;
  height: auto;
  min-height: 315px; /* Ensure the video does not become too short */
  border: 0;
}