.interactive_section {
  background-color: var(--color-accent-4);
  color: var(--color-black);
  text-align: center;
  padding: 5rem 1rem;
}

.color-picker {
  margin-bottom: 1rem;
}

#interactiveCanvas {
  width: 100%;
  height: 400px;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  position: relative;
}