.intro_section {
  background-color: var(--color-accent-1); /* Changed to Purple background */
  color: var(--color-white);
  text-align: center;
  padding: 5rem 1rem;
}

.intro_section a#try-now-btn {
  background-color: var(--color-accent-2); /* Changed to Light Blue background */
  color: var(--color-black);
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 2rem;
  transition: background-color 0.3s ease;
  text-decoration: none; /* Remove underline from link */
  display: inline-block; /* Ensure padding is applied correctly */
  font-weight: bold; /* Make the text bold */
}

.intro_section a#try-now-btn:hover {
  background-color: var(--color-accent-2);
}