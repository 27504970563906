/* General body styling */
html {
  background-color: var(--color-accent-1); /* Set background color to purple */
}

body {
  color: var(--text-color);
  display: flex;
  justify-content: stretch;
}

a {
  color: var(--color-accent-3);
  text-decoration: none;
}

h1 {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 2rem;
}

h2 {
  font-size: 2rem; /* Slightly smaller */
  font-weight: 400; /* Light but not too thin */
  margin-bottom: 2rem;
}