.home_section-2 {
  background-color: var(--color-white); /* White background */
  font-family: var(--font-family-1);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.home_section-2-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
}

.home_section-2-card {
  font-size: 1.5rem;
  display: block;
  width: 25%;
  min-width: 300px;
  text-align: center;
}