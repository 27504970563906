.xibi_about_itself_section {
  background-color: var(--color-accent-1); /* Changed to Purple background */
  color: var(--color-white);
  text-align: center;
  padding: 5rem 1rem;
}

.xibi_about_itself_section h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.xibi_about_itself_section p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.xibi_about_itself_section .centered-paragraphs {
  max-width: 60ch;
  text-align: left;
  margin: 0 auto;
}