@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bevan:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.work-sans-font {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bevan-regular {
  font-family: "Bevan", serif;
  font-weight: 400;
  font-style: normal;
}

.mali-bold {
  font-family: "Mali", cursive;
  font-weight: 700;
  font-style: normal;
}

body {
  font-optical-sizing: auto;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
